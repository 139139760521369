import { Chip } from '@mui/material';
import { differenceInDays, startOfDay, startOfToday } from 'date-fns';
import { PaymentStatus } from 'types';
import { parseDate } from 'utils';

const PaymentStatusChip = ({
  status,
  dueDate,
  minWidth = '120px',
}: {
  status: PaymentStatus;
  dueDate?: string;
  minWidth?: string;
}) => {
  if (status === 'Payment made')
    return (
      <Chip
        label="payment made"
        sx={{
          backgroundColor: 'status.fullyPaid',
          color: 'warning.contrastText',
          minWidth,
        }}
      />
    );

  if (status === 'Promise to pay')
    return (
      <Chip
        label="promise to pay"
        sx={{
          backgroundColor: 'status.paymentPlan',
          color: 'warning.contrastText',
          minWidth,
        }}
      />
    );
  if (!dueDate) return null;
  const daysPastDue = differenceInDays(
    startOfToday(),
    startOfDay(parseDate(dueDate))
  );

  let label = '';
  let backgroundColor = 'warning.light';
  if (daysPastDue <= 0) {
    label = `only ${-daysPastDue} days left`;
  }
  if (daysPastDue === -1) {
    label = `only 1 day left`;
  }
  if (daysPastDue === 0) {
    label = 'due today';
  }
  if (daysPastDue === 1) {
    label = '1 day past due';
  }
  if (daysPastDue > 1) {
    label = `${daysPastDue} days past due`;
  }
  if (daysPastDue > 30) {
    backgroundColor = 'warning.main';
  }
  if (daysPastDue > 60) {
    backgroundColor = 'warning.dark';
  }

  return (
    <Chip
      label={label}
      color="warning"
      sx={{ color: 'warning.contrastText', backgroundColor, minWidth }}
    />
  );
};

export default PaymentStatusChip;
