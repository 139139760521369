import { Box, Typography } from '@mui/material';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { Case } from 'types';
import CaseDetailsHeader from './CaseDetailsHeader';
import CallHistory from './CallHistory';
import { API_URL } from '../../constants';
import CallButton from 'components/call-button/CallButton';
import { useEffect } from 'react';

export const caseLoader = ({ params }: LoaderFunctionArgs) => {
  return fetch(`${API_URL}/api/v1/cases/${params.id}`).then((resp) =>
    resp.json()
  );
};

const CaseDetails = () => {
  const caseId = useParams().id!;
  const caseDetails = useLoaderData() as Case;

  const revalidator = useRevalidator();

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      revalidator.revalidate();
    }, 5000);
    return () => clearInterval(refreshInterval);
  }, [revalidator]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        rowGap="10px"
        mt="40px"
        mb="16px"
      >
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="h4">Case details</Typography>
        </Box>
        {caseDetails.payment_status === 'No progress' ? (
          <CallButton caseId={caseId} />
        ) : null}
      </Box>
      <CaseDetailsHeader caseDetails={caseDetails} />
      <CallHistory
        paymentStatus={caseDetails.payment_status}
        callRecords={caseDetails.call_history}
      />
    </>
  );
};

export default CaseDetails;
