import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

type LoginForm = {
  username: string;
  password: string;
};

const Login = ({ onLogin }: { onLogin: () => void }) => {
  const { handleSubmit, control, formState, setError } = useForm<LoginForm>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = (data: LoginForm) => {
    if (data.username === 'Vanessa' && data.password === 'EM2024') {
      onLogin();
    } else {
      setError('password', { message: 'Invalid username or password' });
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        gap={2}
        sx={{ width: 1 / 2 }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          Vanessa AI Collections Contact Center
        </Typography>
        <Controller
          name="username"
          control={control}
          rules={{
            required: 'Username is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Username"
              placeholder="Enter username"
              slotProps={{ inputLabel: { shrink: true } }}
              error={Boolean(formState.errors.username)}
              helperText={formState.errors.username?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Password is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              placeholder="Enter password"
              type="password"
              slotProps={{ inputLabel: { shrink: true } }}
              error={Boolean(formState.errors.password)}
              helperText={formState.errors.password?.message}
            />
          )}
        />
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Stack>
    </Container>
  );
};

export default Login;
