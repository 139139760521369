import axios from 'axios';
import { API_URL } from '../constants';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Case } from 'types';

const casesContext = createContext<{
  cases: Case[];
  refreshCases: () => Promise<void>;
}>({ cases: [], refreshCases: () => Promise.resolve() });

const CasesProvider = ({ children }: PropsWithChildren) => {
  const [cases, setCases] = useState<Case[]>([]);

  const fetchCases = useCallback(
    () =>
      axios
        .get<{ [key: string]: Omit<Case, 'id'> }>(`${API_URL}/api/v1/cases`)
        .then((response) => {
          const records: Case[] = Object.entries(response.data).map(
            ([entry, value]) => ({ id: entry, ...value })
          );
          setCases(records);
        })
        .catch((error) => {
          console.error('There was an error fetching the data!', error);
        }),
    []
  );

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  return (
    <casesContext.Provider value={{ cases, refreshCases: fetchCases }}>
      {children}
    </casesContext.Provider>
  );
};

export const useCases = () => {
  return useContext(casesContext);
};

export default CasesProvider;
