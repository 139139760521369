import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { CallRecord, PaymentStatus } from 'types';
import PaymentStatusChip from './PaymentStatusChip';

const CallOutcomeChip = ({
  callRecord,
  paymentStatus,
  width = '100%',
}: {
  callRecord: CallRecord;
  paymentStatus: PaymentStatus;
  width?: string;
}) => {
  const { color, label } = useMemo(() => {
    return {
      label: callRecord.disposition_tag,
      color: 'status.inProgress',
    };
  }, [callRecord]);

  if (callRecord.form_submission_status === 'completed')
    return <PaymentStatusChip status={paymentStatus} minWidth={width} />;

  if (!label) return null;

  return (
    <Chip
      label={label.replaceAll('_', ' ').toLowerCase()}
      sx={{
        backgroundColor: color,
        color: 'warning.contrastText',
        width: width,
      }}
    />
  );
};

export default CallOutcomeChip;
