import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import { MedicalServicesOutlined } from '@mui/icons-material';
import { API_URL } from '../../constants';
import { useCases } from 'providers/CasesProvider';
import { useForm, Controller } from 'react-hook-form';
import currency from 'currency.js';

interface FormData {
  clientName: string;
  clientPhoneNumber: string;
  product: string;
  outstandingBalance: string;
  minimumPayment: string;
  apr: string;
  context: string;
  dueDate: Date | null;
  bankName: string;
  accountLastDigits: string;
}

function OverviewBar() {
  const [open, setOpen] = useState(false);
  const { refreshCases } = useCases();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      clientName: "Elton John",
      product: "Credit card",
      outstandingBalance: '27,000',
      minimumPayment: '1000',
      apr: '19.99',
      accountLastDigits: '8256',
      context: 'Typically makes payments on time, but has missed the last 2 payments',
      dueDate: new Date('2024-09-22T05:00:00.000Z'),
      bankName: 'E M Banking',
    },
  });

  const handleOpen = () => {
    reset();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onSubmit = async (data: FormData) => {
    const caseData = {
      client_name: data.clientName,
      client_phone_number: data.clientPhoneNumber,
      product: data.product,
      outstanding_balance: currency(data.outstandingBalance).value,
      due_date: data.dueDate?.toISOString().split('T')?.at(0), // Format date to YYYY-MM-DD
      payment_status: 'No progress',
      call_history: [],
      bank_name: data.bankName,
      account_number_last_4_digits: data.accountLastDigits,
      minimum_payment: currency(data.minimumPayment).value,
      apr_rate: parseFloat(data.apr),
      case_context: data.context,
    };
    try {
      const response = await fetch(`${API_URL}/api/v1/cases`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(caseData),
      });
      if (response.ok) {
        refreshCases();
        handleClose();
      } else {
        console.error('Failed to create case');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'inherit',
        boxShadow: 'none',
        color: 'black',
      }}
    >
      <Toolbar
        disableGutters
        sx={{ mt: '40px', mb: '16px', minHeight: 'auto !important' }}
      >
        <Box sx={{ flexGrow: 2, display: { xs: 'inherit', md: 'flex' } }}>
          <Typography
            noWrap
            variant="h4"
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'inherit', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Cases overview
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'inherit', md: 'flex' } }}>
          <Button
            variant={'contained'}
            size={'small'}
            color={'primary'}
            key="Addcase"
            onClick={handleOpen}
            endIcon={<MedicalServicesOutlined sx={{ mr: 1 }} />}
          >
            ADD NEW CASE
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a new case</DialogTitle>
            <DialogContent dividers>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="clientName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Client Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter full name'}
                      variant="standard"
                      label="Client Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.clientName}
                      helperText={errors.clientName?.message}
                    />
                  )}
                />
                <Controller
                  name="clientPhoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Client Phone Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      variant="standard"
                      label="Client Phone Number"
                      placeholder={'+1 (999) 999-9999'}
                      fullWidth
                      margin="normal"
                      error={!!errors.clientPhoneNumber}
                      helperText={errors.clientPhoneNumber?.message}
                    />
                  )}
                />
                <Controller
                  name="product"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Product is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth margin="normal" variant="standard">
                      <InputLabel id="product-label" shrink>
                        Product
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="product-label"
                        displayEmpty
                        label="Product"
                        placeholder="Select a product"
                        error={!!errors.product}
                      >
                        <MenuItem value="">
                          <em>Select a product</em>
                        </MenuItem>
                        <MenuItem value="Credit card">Credit card</MenuItem>
                        <MenuItem value="Personal line of credit">
                          Personal line of credit
                        </MenuItem>
                        <MenuItem value="Personal car loan">
                          Personal car loan
                        </MenuItem>
                      </Select>
                      {errors.product && (
                        <Typography variant="body2" color="error">
                          {errors.product.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="outstandingBalance"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Outstanding Balance is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      placeholder={'0,000.00'}
                      variant="standard"
                      label="Outstanding Balance"
                      fullWidth
                      margin="normal"
                      error={!!errors.outstandingBalance}
                      helperText={errors.outstandingBalance?.message}
                    />
                  )}
                />
                <Controller
                  name="minimumPayment"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Minimum Payment is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      placeholder={'0,000.00'}
                      variant="standard"
                      label="Minimum Payment"
                      fullWidth
                      margin="normal"
                      error={!!errors.minimumPayment}
                      helperText={errors.minimumPayment?.message}
                    />
                  )}
                />
                <Controller
                  name="apr"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'APR Interest Rate is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        },
                      }}
                      placeholder={'00.00'}
                      variant="standard"
                      label="APR Interest Rate"
                      fullWidth
                      margin="normal"
                      error={!!errors.apr}
                      helperText={errors.apr?.message}
                    />
                  )}
                />
                <Controller
                  name="bankName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Bank name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                      variant="standard"
                      label="Bank Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.bankName}
                      helperText={errors.bankName?.message}
                    />
                  )}
                />
                <Controller
                  name="accountLastDigits"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Account number last 4 digits is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        htmlInput: {
                          minLength: 4,
                          maxLength: 4,
                        },
                      }}
                      placeholder={'Enter account number last 4 digits'}
                      variant="standard"
                      label="Account number last 4 digits"
                      fullWidth
                      margin="normal"
                      error={!!errors.accountLastDigits}
                      helperText={errors.accountLastDigits?.message}
                    />
                  )}
                />
                <Controller
                  name="context"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Case context is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                      placeholder={'Add additional case context here'}
                      variant="standard"
                      label="Case context"
                      fullWidth
                      multiline
                      margin="normal"
                      error={!!errors.context}
                      helperText={errors.context?.message}
                    />
                  )}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dueDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: 'Payment Due Date is required' }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        slotProps={{
                          textField: {
                            placeholder: 'MM/DD/YYYY',
                            InputLabelProps: { shrink: true },
                            error: !!errors.dueDate,
                            helperText: errors.dueDate?.message,
                          },
                        }}
                        sx={{ width: '100%', my: 2 }}
                        label="Payment Due Date"
                      />
                    )}
                  />
                </LocalizationProvider>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Create a Case
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default OverviewBar;
