import { parse } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    return parsePhoneNumber(phoneNumber).formatInternational();
  } catch (error) {
    return phoneNumber;
  }
};

export const parseDate = (date: string) => {
  return parse(date, 'yyyy-MM-dd', new Date());
};
