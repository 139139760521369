import { Case } from 'types';
import Section from 'components/section/Section';
import { ReactNode, useMemo } from 'react';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import currency from 'currency.js';
import { cyan, green } from '@mui/material/colors';

const getCasesSummary = (
  cases: Case[],
  property: 'outstanding_balance' | 'minimum_payment'
) => {
  const totalAmount = cases.reduce(
    (acc, current) => acc.add(currency(current[property])),
    currency(0)
  );

  return {
    count: cases.length,
    amount: currency(totalAmount, {
      separator: ',',
      decimal: '.',
      symbol: '',
    }).format(),
  };
};

const BlockItem = ({
  label,
  value,
  sx,
}: {
  label: string;
  value: ReactNode;
  sx?: SxProps;
}) => (
  <Stack gap={2} alignItems="center" sx={sx}>
    <Typography variant="subtitle2">{label}</Typography>
    {value}
  </Stack>
);

const DashboardStrip = ({
  openCases,
  inProgressCases,
  closedCases,
}: {
  openCases: Case[];
  inProgressCases: Case[];
  closedCases: Case[];
}) => {
  const open = useMemo(
    () => getCasesSummary([...openCases, ...inProgressCases], 'outstanding_balance'),
    [openCases, inProgressCases]
  );
  const closed = useMemo(
    () => getCasesSummary(closedCases, 'minimum_payment'),
    [closedCases]
  );
  return (
    <Section sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
      <BlockItem
        label="Cases open"
        value={
          <Typography variant="h2" color={cyan['A700']}>
            {open.count}
          </Typography>
        }
        sx={{ flexGrow: 1 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Amount to be collected"
        value={
          <Typography variant="h2" color={green['A700']}>
            {open.amount}
          </Typography>
        }
        sx={{ flexGrow: 2 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Cases closed"
        value={<Typography variant="h2">{closed.count}</Typography>}
        sx={{ flexGrow: 1 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Amount collected"
        value={<Typography variant="h2">{closed.amount}</Typography>}
        sx={{ flexGrow: 2 }}
      />
    </Section>
  );
};

export default DashboardStrip;
