import { Box, Divider, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import Section from 'components/section/Section';
import currency from 'currency.js';
import { format, parse } from 'date-fns';
import { ReactNode } from 'react';
import { formatPhoneNumber, parseDate } from 'utils';
import PaymentStatusChip from './PaymentStatusChip';
import { Case } from 'types';

const parseCallDate = (date: string) =>  parse(
  date,
  'EEEE, MMMM dd, yyyy hh:mm a',
  new Date()
)

const CardItem = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <Stack flex={1} gap="21px">
    <Typography variant="subtitle2">{label}</Typography>
    <Typography variant="h2">{value}</Typography>
  </Stack>
);

const CaseDetailsHeader = ({ caseDetails }: { caseDetails: Case }) => {
  const [lastCall] = caseDetails.call_history.sort((a, b) => parseCallDate(b.call_time_utc).getTime() - parseCallDate(a.call_time_utc).getTime())
  return (
    <Section sx={{ px: '40px' }}>
      <Stack gap="40px">
        <Stack
          direction="row"
          gap="35px"
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <CardItem label="Client name" value={caseDetails.client_name} />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          />
          <CardItem
            label={
              <Box display="flex" gap={2}>
                Payment due date{' '}
                {caseDetails.payment_status === 'No progress' ? (
                  <PaymentStatusChip
                    dueDate={caseDetails.due_date}
                    status={caseDetails.payment_status}
                  />
                ) : null}
              </Box>
            }
            value={format(parseDate(caseDetails.due_date), 'MMMM dd, yyyy')}
          />
        </Stack>
        <Stack
          direction="row"
          gap="35px"
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <CardItem
            label="Client phone number"
            value={
              <Box component="span" sx={{ color: 'secondary.dark' }}>
                {formatPhoneNumber(caseDetails.client_phone_number)}
              </Box>
            }
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          />
          <CardItem
            label="Outstanding balance"
            value={
              <Box
                component="div"
                display="flex"
                alignItems="baseline"
                sx={{ color: green['A700'] }}
              >
                <Typography variant="h4">$</Typography>
                {currency(caseDetails.outstanding_balance, { symbol: '' }).format()}
              </Box>
            }
          />
        </Stack>
        {lastCall ? (
          <Stack sx={{ background: '#F5F5F5', p: 3 }} gap="21px">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">
                <strong>Resolution summary</strong>
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {format(
                  parseCallDate(lastCall.call_time_utc),
                  'MMMM dd, yyyy'
                )}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {lastCall.call_summary}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Section>
  );
};

export default CaseDetailsHeader;
