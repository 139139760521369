import { Box, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import DashboardStrip from 'components/dashboard-strip/DashboardStrip';
import CasesSection from 'components/dashboard/CasesSection';
import { WorkOffOutlined, WorkOutline } from '@mui/icons-material';
import { blueGrey } from '@mui/material/colors';
import { useCases } from 'providers/CasesProvider';
import OverviewBar from 'components/overview-bar/OverviewBar';
import { RESOLVED_CASE_PAYMENT_STATUSES } from '../../constants';

const Dashboard = () => {
  const { cases: data, refreshCases } = useCases();

  const openCases = useMemo(
    () =>
      data.filter(
        ({ payment_status, call_history }) =>
          ['No progress'].includes(payment_status) && call_history.length === 0
      ),
    [data]
  );
  const inProgressCases = useMemo(
    () =>
      data.filter(
        ({ payment_status, call_history }) =>
          ['No progress'].includes(payment_status) && call_history.length > 0
      ),
    [data]
  );
  const closedCases = useMemo(
    () =>
      data.filter(({ payment_status }) =>
        RESOLVED_CASE_PAYMENT_STATUSES.includes(payment_status)
      ),
    [data]
  );

  useEffect(() => {
    const refreshInterval = setInterval(() => refreshCases(), 5000);
    return () => clearInterval(refreshInterval);
  }, [refreshCases]);

  return (
    <>
      <OverviewBar />
      <DashboardStrip
        openCases={openCases}
        closedCases={closedCases}
        inProgressCases={inProgressCases}
      />
      <CasesSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOutline sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">New cases</Typography>
          </Box>
        }
        cases={openCases}
      />
      <CasesSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOutline sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">In progress</Typography>
          </Box>
        }
        cases={inProgressCases}
      />
      <CasesSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOffOutlined sx={{ color: blueGrey[800] }} />
            <Typography variant="h5">Resolved cases</Typography>
          </Box>
        }
        cases={closedCases}
      />
    </>
  );
};

export default Dashboard;
