import Section from 'components/section/Section';
import { ReactNode } from 'react';
import { format } from 'date-fns';
import { Case } from 'types';
import currency from 'currency.js';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber, parseDate } from 'utils';
import PaymentStatusChip from 'components/case/PaymentStatusChip';
import Table, { TableModel } from 'components/table/Table';
import { Box } from '@mui/material';
import CallButton from '../call-button/CallButton';
import CallOutcomeChip from '../case/CallOutcomeChip';
import { RESOLVED_CASE_PAYMENT_STATUSES } from '../../constants';

const CaseStatus = ({ item }: { item: Case }) => (
  <Box display="flex" alignItems="center" gap="25px">
    {item.payment_status === 'No progress' && item.call_history.length > 0 ? (
      <CallOutcomeChip
        callRecord={item.call_history[0]}
        paymentStatus={item.payment_status}
        width="160px"
      />
    ) : (
      <PaymentStatusChip
        minWidth="160px"
        status={item.payment_status}
        dueDate={item.due_date}
      />
    )}
    {!RESOLVED_CASE_PAYMENT_STATUSES.includes(item.payment_status) && (
      <CallButton caseId={item.id} />
    )}
  </Box>
);

const CasesSection = ({
  title,
  cases,
}: {
  title: ReactNode;
  cases: Case[];
}) => {
  const navigate = useNavigate();

  const tableModel: TableModel<Case> = {
    columns: [
      {
        width: '19%',
        label: 'Client name',
        render: (item) => item.client_name,
      },
      {
        width: '19%',
        label: 'Client phone number',
        render: (item) => (
          <Box component="span" sx={{ color: 'secondary.dark' }}>
            {formatPhoneNumber(item.client_phone_number)}
          </Box>
        ),
      },
      {
        width: '19%',
        label: 'Outstanding balance',
        render: (item) =>
          currency(item.outstanding_balance, {
            precision: 2,
          }).format(),
      },
      {
        width: '19%',
        label: 'Payment due date',
        render: (item) => format(parseDate(item.due_date), 'MMMM dd'),
      },
      {
        width: '25%',
        label: 'Status',
        render: (item) => <CaseStatus item={item} />,
      },
    ],
  };

  return (
    <Section title={title}>
      <Table
        data={cases}
        tableModel={tableModel}
        onRowClick={(item) => navigate(`/cases/${item.id}`)}
      />
    </Section>
  );
};

export default CasesSection;
