import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoIcon from '../../assets/LogoIcon';
import {
  CalendarMonthOutlined,
  Dashboard,
  InsertChartOutlined,
  NotificationsOutlined,
  Settings,
} from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'components/login/AuthProvider';

const pages = ['Dashboard', 'Calendar', 'Stats'];

function ResponsiveAppBar() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const { logout } = useAuth();

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: 'inherit', boxShadow: 'none', color: 'black' }}
    >
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' } }}>
          <LogoIcon
            sx={{
              display: {
                height: '40px',
                width: '40px',
              },
              mr: 1,
            }}
          />
          <Typography
            noWrap
            variant="h6"
            component={Link}
            to="/"
            sx={{
              mr: 2,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Vanessa AI Collections Contact Center
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flex: 1, display: { xs: 'flex', md: 'none' } }}>
          <LogoIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              color: 'inherit',
              fontSize: '20px',
              textDecoration: 'none',
            }}
          >
            Vanessa AI Collections Contact Center
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            justifyContent: 'center',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Tabs
            value={location.pathname === '/dashboard' ? 0 : false}
            onChange={() => {}}
            centered
          >
            <Tab
              icon={<Dashboard />}
              iconPosition={'start'}
              label="Dashboard"
              defaultChecked={true}
              sx={{ minHeight: 'auto' }}
              onClick={() => navigate('/dashboard')}
            />
            <Tab
              icon={<CalendarMonthOutlined />}
              iconPosition={'start'}
              label="Calendar"
              sx={{ minHeight: 'auto' }}
            />
            <Tab
              icon={<InsertChartOutlined />}
              iconPosition={'start'}
              label="Stats"
              sx={{ minHeight: 'auto' }}
            />
          </Tabs>
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end" gap={1}>
          <Tooltip title="Open settings">
            <IconButton sx={{ backgroundColor: 'white', boxShadow: 1 }}>
              <Settings sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open Notifications">
            <IconButton sx={{ backgroundColor: 'white', boxShadow: 1 }}>
              <NotificationsOutlined sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open Profile Settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white' }}>V</Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => {
              handleCloseUserMenu();
              setAnchorElUser(null);
            }}
          >
            <MenuItem onClick={logout}>
              <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
