import {LibraryBooks, PhoneCallback} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import Section from 'components/section/Section';
import Table, {TableModel} from 'components/table/Table';
import {format, parse} from 'date-fns';
import {CallRecord, PaymentStatus} from 'types';
import CallOutcomeChip from './CallOutcomeChip';
import {useState} from 'react';
import Transcript from './Transcript';
import AudioRecordingButton from './AudioRecordingButton';

const CallHistory = ({ callRecords , paymentStatus}: { callRecords: CallRecord[], paymentStatus: PaymentStatus }) => {
  const [transcript, setTranscript] = useState<string>();

  const closeTranscript = () => {
    setTranscript(undefined);
  };

  const tableModel: TableModel<CallRecord> = {
    columns: [
      {
        label: 'Date contacted',
        width: '30%',
        render: (item) => {
          try {
            return format(
              parse(
                item.call_time_utc,
                'EEEE, MMMM dd, yyyy hh:mm a',
                new Date()
              ),
              'MMMM dd, yyyy'
            );
          } catch (error) {
            console.error(error);
            return item.call_time_utc;
          }
        },
      },
      {
        label: 'Transcript of the call',
        width: '25%',
        render: (item) => (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setTranscript(item.call_transcript)}
            startIcon={<LibraryBooks />}
            sx={{ height: { xs: '100%', sm: 'auto' } }}
          >
            VIEW TRANSCRIPT
          </Button>
        ),
      },
      {
        label: 'Recording of the call',
        width: '25%',
        render: (item) => <AudioRecordingButton callId={item.call_id} />,
      },
      {
        label: 'Call outcome',
        width: '10%',
        render: (item) => <CallOutcomeChip paymentStatus={paymentStatus} callRecord={item} />,
      },
    ],
  };

  return (
    <>
      <Section
        title={
          <Box display="flex" gap={1} alignItems="center">
            <PhoneCallback sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">Call history</Typography>
          </Box>
        }
      >
        <Table data={callRecords} tableModel={tableModel} />
      </Section>
      {Boolean(transcript) && (
        <Transcript transcript={transcript!} onClose={closeTranscript} />
      )}
    </>
  );
};

export default CallHistory;
