import React from 'react';

import './App.css';
import { Stack } from '@mui/material';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import Root from 'components/root/root';
import Dashboard from 'components/dashboard/Dashboard';
import CaseDetails, { caseLoader } from 'components/case/CaseDetails';
import AuthProvider from 'components/login/AuthProvider';

const Index = () => {
  return <Navigate to="/dashboard" />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <Index /> },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'calendar',
        element: null,
      },
      {
        path: 'stats',
        element: null,
      },
      {
        path: 'cases/:id',
        element: <CaseDetails />,
        loader: caseLoader,
        errorElement: <Navigate to="/dashboard" />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Stack gap={2} p={3} height="100%" minHeight="100vh">
        <RouterProvider router={router} />
      </Stack>
    </AuthProvider>
  );
};

export default App;
